import React, { useState, useEffect, useCallback } from 'react';
import { Server, AlertCircle, Zap, DollarSign, Shield, Lock, Snowflake, Globe, Cpu } from 'lucide-react';

const DataCenterTycoon = () => {
  const [money, setMoney] = useState(2000);
  const [racks, setRacks] = useState([{ id: 1, servers: [] }]);
  const [day, setDay] = useState(1);
  const [gameLog, setGameLog] = useState([]);
  const [dailyProfit, setDailyProfit] = useState(0);
  const [unlocks, setUnlocks] = useState({
    cooling: { unlocked: false, cost: 5000, effect: 0.5 },
    security: { unlocked: false, cost: 7500, effect: 0.5 },
    automation: { unlocked: false, cost: 10000, effect: 0.5 },
  });

  const serverCost = 500;
  const rackCost = 2000;
  const dailyServerIncome = 150;
  const dailyMaintenanceCost = 10;
  const maxServersPerRack = 5;


  const addLogEntry = useCallback((entry) => {
    setGameLog(prevLog => [entry, ...prevLog].slice(0, 10));
  }, []);

  const updateMoney = useCallback((amount, reason) => {
    setMoney(prevMoney => {
      const newMoney = prevMoney + amount;
      addLogEntry(`${reason}: ${amount > 0 ? '+' : ''}$${amount.toFixed(2)} (Balance: $${newMoney.toFixed(2)})`);
      return newMoney;
    });
  }, [addLogEntry]);

  const calculateDailyProfit = useCallback(() => {
    let income = 0;
    let expenses = 0;
    racks.forEach(rack => {
      rack.servers.forEach(server => {
        if (server.status === 'online') {
          income += dailyServerIncome;
        }
        expenses += dailyMaintenanceCost;
      });
    });
    return income - expenses;
  }, [racks]);

  const updateGameState = useCallback(() => {
    const profit = calculateDailyProfit();
    setDailyProfit(profit);
    updateMoney(profit, 'Daily profit');

    addLogEntry(`Day ${day} report: Profit $${profit.toFixed(2)}`);

    setRacks(prevRacks => prevRacks.map(rack => ({
      ...rack,
      servers: rack.servers.map(server => {
        if (server.status === 'online') {
          const baseFailureChance = 0.1;
          const baseDdosChance = 0.08;
          const baseUnpaidChance = 0.05;

          const failureChance = baseFailureChance * (unlocks.cooling.unlocked ? unlocks.cooling.effect : 1);
          const ddosChance = baseDdosChance * (unlocks.security.unlocked ? unlocks.security.effect : 1);
          const unpaidChance = baseUnpaidChance * (unlocks.automation.unlocked ? unlocks.automation.effect : 1);

          const roll = Math.random();
          if (roll < failureChance) {
            addLogEntry(`Server ${server.id} experienced a hardware failure!`);
            return { ...server, status: 'hardware-failure' };
          } else if (roll < failureChance + ddosChance) {
            addLogEntry(`Server ${server.id} is under DDoS attack!`);
            return { ...server, status: 'ddos-attack' };
          } else if (roll < failureChance + ddosChance + unpaidChance) {
            addLogEntry(`Customer didn't pay for Server ${server.id}!`);
            return { ...server, status: 'unpaid' };
          }
        }
        return server;
      })
    })));
  }, [day, calculateDailyProfit, updateMoney, addLogEntry, unlocks]);

  useEffect(() => {
    const gameLoop = setInterval(() => {
      setDay(prevDay => prevDay + 1);
      updateGameState();
    }, 1000);
    return () => clearInterval(gameLoop);
  }, [updateGameState]);

  const addServer = useCallback((rackId) => {
    if (money >= serverCost) {
      updateMoney(-serverCost, 'Added new server');
      setRacks(prevRacks => prevRacks.map(rack =>
        rack.id === rackId && rack.servers.length < maxServersPerRack
          ? { ...rack, servers: [...rack.servers, { id: `${rackId}-${rack.servers.length + 1}`, status: 'online' }] }
          : rack
      ));
      addLogEntry(`New server added to Rack ${rackId}`);
    }
  }, [money, serverCost, updateMoney, addLogEntry, maxServersPerRack]);

  const addRack = useCallback(() => {
    if (money >= rackCost) {
      updateMoney(-rackCost, 'Added new rack');
      setRacks(prevRacks => [...prevRacks, { id: prevRacks.length + 1, servers: [] }]);
      addLogEntry(`New rack added (ID: ${racks.length + 1})`);
    }
  }, [money, rackCost, updateMoney, addLogEntry, racks.length]);

  const fixServer = useCallback((rackId, serverId) => {
    const fixCost = 200;
    if (money >= fixCost) {
      updateMoney(-fixCost, `Fixed server ${serverId}`);
      setRacks(prevRacks => prevRacks.map(rack =>
        rack.id === rackId
          ? { ...rack, servers: rack.servers.map(server =>
              server.id === serverId ? { ...server, status: 'online' } : server
            ) }
          : rack
      ));
      addLogEntry(`Server ${serverId} in Rack ${rackId} fixed and back online`);
    } else {
      addLogEntry(`Not enough money to fix Server ${serverId}`);
    }
  }, [money, updateMoney, addLogEntry]);

  const unlockUpgrade = useCallback((upgrade) => {
    if (money >= unlocks[upgrade].cost && !unlocks[upgrade].unlocked) {
      updateMoney(-unlocks[upgrade].cost, `Unlocked ${upgrade} upgrade`);
      setUnlocks(prevUnlocks => ({
        ...prevUnlocks,
        [upgrade]: { ...prevUnlocks[upgrade], unlocked: true }
      }));
      addLogEntry(`${upgrade.charAt(0).toUpperCase() + upgrade.slice(1)} upgrade unlocked!`);
    }
  }, [money, unlocks, updateMoney, addLogEntry]);



  const buttonStyle = {
    padding: '8px 16px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  };

  const disabledButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#cccccc',
    cursor: 'not-allowed',
  };

  const renderServer = useCallback((server, rackId) => {
    let icon, color;
    switch (server.status) {
      case 'online':
        icon = <Server style={{color: '#4CAF50'}} />;
        color = '#4CAF50';
        break;
      case 'hardware-failure':
        icon = <AlertCircle style={{color: '#f44336'}} />;
        color = '#f44336';
        break;
      case 'ddos-attack':
        icon = <Zap style={{color: '#ffeb3b'}} />;
        color = '#ffeb3b';
        break;
      case 'unpaid':
        icon = <DollarSign style={{color: '#ff9800'}} />;
        color = '#ff9800';
        break;
    }

    return (
      <div key={server.id} style={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
        {icon}
        <div style={{flexGrow: 1, height: '8px', backgroundColor: color, marginLeft: '8px', marginRight: '8px', borderRadius: '4px'}}></div>
        {server.status !== 'online' && (
          <button 
            onClick={() => fixServer(rackId, server.id)} 
            disabled={money < 200}
            style={money < 200 ? disabledButtonStyle : buttonStyle}
          >
            Fix ($200)
          </button>
        )}
      </div>
    );
  }, [fixServer, money]);

  const renderRack = useCallback((rack) => (
    <div key={rack.id} style={{marginBottom: '16px', border: '1px solid #ccc', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9'}}>
      <h3 style={{marginBottom: '12px'}}>Rack {rack.id}</h3>
      {rack.servers.map(server => renderServer(server, rack.id))}
      {rack.servers.length < maxServersPerRack && (
        <button 
          onClick={() => addServer(rack.id)} 
          disabled={money < serverCost} 
          style={{...(money < serverCost ? disabledButtonStyle : buttonStyle), width: '100%', marginTop: '8px'}}
        >
          Add Server (${serverCost})
        </button>
      )}
    </div>
  ), [addServer, money, serverCost, maxServersPerRack, renderServer]);

  const renderUpgradeButton = useCallback((key, value) => {
    let icon, description;
    switch (key) {
      case 'cooling':
        icon = <Snowflake />;
        description = "Reduces hardware failure chance by 50%";
        break;
      case 'security':
        icon = <Globe />;
        description = "Reduces DDoS attack chance by 50%";
        break;
      case 'automation':
        icon = <Cpu />;
        description = "Reduces unpaid customer chance by 50%";
        break;
    }

    return (
      <div key={key} title={description} style={{marginBottom: '8px'}}>
        <button
          onClick={() => unlockUpgrade(key)}
          disabled={value.unlocked || money < value.cost}
          style={{
            ...(value.unlocked || money < value.cost ? disabledButtonStyle : buttonStyle),
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {value.unlocked ? <Shield style={{marginRight: '8px'}} /> : <Lock style={{marginRight: '8px'}} />}
          {icon}
          <span style={{marginLeft: '8px'}}>{key.charAt(0).toUpperCase() + key.slice(1)} (${value.cost})</span>
        </button>
      </div>
    );
  }, [unlockUpgrade, money]);

  return (
    <div style={{padding: '16px', display: 'flex', height: '100vh', fontFamily: 'Arial, sans-serif'}}>
      <div style={{width: '66%', paddingRight: '16px', overflowY: 'auto'}}>
        <h1 style={{fontSize: '24px', fontWeight: 'bold', marginBottom: '16px'}}>DataCenter Tycoon</h1>
        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px', backgroundColor: '#e0e0e0', padding: '12px', borderRadius: '8px'}}>
          <div>Day: {day}</div>
          <div>Money: ${money.toFixed(2)}</div>
          <div>Daily Profit: ${dailyProfit.toFixed(2)}</div>
        </div>
        <button 
          onClick={addRack} 
          disabled={money < rackCost} 
          style={{...(money < rackCost ? disabledButtonStyle : buttonStyle), width: '100%', marginBottom: '16px'}}
        >
          Add Rack (${rackCost})
        </button>
        {racks.map(renderRack)}
      </div>
      <div style={{width: '33%', display: 'flex', flexDirection: 'column'}}>
        <div style={{marginBottom: '16px', border: '1px solid #ccc', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9'}}>
          <h2 style={{marginBottom: '12px'}}>Upgrades</h2>
          {Object.entries(unlocks).map(([key, value]) => renderUpgradeButton(key, value))}
        </div>
        <div style={{border: '1px solid #ccc', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9', flexGrow: 1, overflowY: 'auto'}}>
          <h2 style={{marginBottom: '12px'}}>Game Log</h2>
          <ul style={{listStyleType: 'none', padding: 0}}>
            {gameLog.map((log, index) => (
              <li key={index} style={{fontSize: '14px', marginBottom: '4px', padding: '4px', backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'transparent'}}>{log}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DataCenterTycoon;
